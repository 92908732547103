var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cardless-base',[_c('PageTitle',{attrs:{"title":"Reimpressão de etiquetas"}}),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('b-form-select',{staticClass:"invision-input",attrs:{"options":_vm.selectOptions,"value":_vm.selected},on:{"input":function (v) { return _vm.handleChangeOptions(v); }},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{domProps:{"value":null}},[_vm._v(" -- Por favor, selecione uma opção -- ")])]},proxy:true}])})],1)],1),(_vm.selected)?_c('b-col',_vm._l((_vm.tiposReimpressao[_vm.selected].inputs),function(input){return _c(input.component,{key:("input-" + (input.key)),tag:"component",staticClass:"mb-4",attrs:{"route":input.route,"item":_vm.item},on:{"updateModel":_vm.updateModel}})}),1):_vm._e()],1),_c('template-base',[_c('div',{staticClass:"base-header mb-4"},[_c('b-row',[_c('b-col',[_c('span',{staticClass:"text"},[_vm._v("Últimos cadastros em lote")])]),(_vm.selected !== 'etiqueta_material')?_c('b-col',[_c('GenericPrint',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"printer-button",attrs:{"hasNoSelecetdMaterials":_vm.idsMaterialToPrint.length === 0 && _vm.idsMaterialGenericoToPrint.length === 0,"title":"Imprimir","tipo":_vm.item ? _vm.item.tipo : '',"data":{
                createdAt: _vm.createdAtReference,
                idsMaterial: _vm.idsMaterialToPrint,
                idsProcessoCiclo: _vm.idsProcessoCicloToPrint,
                id_material_generico: _vm.idsMaterialGenericoToPrint,
                reimpressaoData: _vm.reimpressaoData,
              },"hasReimpressaoDataByDefault":_vm.item ? _vm.item.hasReimpressaoDataByDefault : false}})],1):_c('b-col',{staticClass:"printer-button"},[_c('printer-icon',{staticClass:"w20px pointer align-middle",on:{"click":function($event){return _vm.showModalReport()}}}),_c('Modal',{key:"modalEtiquetaMaterial",ref:"modalEtiquetaMaterial",attrs:{"id":"modalEtiquetaMaterial","name":"modalEtiquetaMaterial","hide-footer":"","no-close-on-esc":"","no-close-on-backdrop":"","hideXClose":_vm.handlerXButton},on:{"hidden":_vm.closingModal}},[_c('Printer',{attrs:{"resultData":_vm.itensToPrint,"buttonName":"IMPRIMIR","hideSection":"section","hideContent":"content","isMaterialTag":true,"indexMaterial":_vm.indexMaterial},on:{"successSend":_vm.successZPL,"wants-exit":_vm.wantsExit,"show-xclose":_vm.handleXButton},scopedSlots:_vm._u([{key:"printContent",fn:function(){return [_c('EtiquetaMaterial',{attrs:{"relatorioData":_vm.itensToPrint,"indexMaterial":_vm.indexMaterial}})]},proxy:true}])})],1)],1)],1)],1),_c('b-table',{attrs:{"striped":"","items":_vm.materiais,"fields":_vm.fields},scopedSlots:_vm._u([{key:"head(checkAll)",fn:function(){return [_c('b-form-checkbox',{attrs:{"indeterminate":_vm.indeterminate,"checked":_vm.allChecked},on:{"change":_vm.checkOrUncheckAll}})]},proxy:true},{key:"cell(checkAll)",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [_c('b-form-checkbox',{attrs:{"checked":item.checked},on:{"change":function($event){return _vm.addOrRemoveNewItemToPrint(item, $event, index)}}})]}}])},[(_vm.isBusy)?_c('b-spinner',{staticClass:"align-middle"}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }