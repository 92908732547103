<template>
  <InputSearch
    ref="inputMaterialIdentificacao"
    :route="route"
    placeholder='Digite o ID ou leia o código de um dos materiais...'
    v-model="filters.codeOrId"
    :filters="filters"
    @update="updateModel"
  />
</template>

<script>
import InputSearch from '@/components/Form/InputSearch';

export default {
  name: 'InputMaterialIdentificacao',

  components: {
    InputSearch,
  },

  data() {
    return {
      filters: {
        acao: this.item.acao ? this.item.acao : null,
      },
    };
  },

  methods: {
    updateModel(v) {
      this.$emit('updateModel', v);
    },
  },

  props: {
    route: {
      type: String,
    },
    item: {
      type: Object,
    },
  },
};
</script>
